<template>
  <div>
    <div
      class="relative shadow bg-white py-[0.625rem] flex items-center space-x-3 justify-between pl-[0.661rem] pr-4"
    >
      <div class="flex items-center space-x-2 font-bold text-xs">
        <div class="flex items-center space-x-4 w-fit">
          <Menu class="flex md:hidden" @click="toggleMenu" />
          <Logo class="md:block w-fit" />
          <!-- <MobilLogo class="flex md:hidden" /> -->
        </div>

        <!-- <div>
       <span class="uppercase text-brandBlue py-[3px] px-[6px] font-bold rounded-full bg-warning">
         PRO
       </span>
     </div> -->
      </div>

      <div class="flex items-center">
        <!-- <status
          status="active"
          :value="$t('index.active')"
          v-if="accountInfo.isActive"
        /> -->
        <div
          class="flex justify-center items-center space-x-4 cursor-pointer"
          ref="logPlace"
        >
          <div
            class="shadow rounded-full w-[2.5rem] h-[2.5rem] flex justify-center items-center mx-4"
            @click="toggle"
          >
            <span
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                /></svg
            ></span>
            <!-- <img
              class="shadow-sm border rounded-full w-[2.5rem] h-[2.5rem]"
              src="https://images.unsplash.com/photo-1544427920-c49ccfb85579?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1422&q=8"
              alt=""
            /> -->
          </div>
          <div class="hidden md:block" @click="toggle">
            <span class="block font-semibold text-[8px] md:text-sm">
              {{ accountInfo.firstname }} {{ accountInfo.lastname }}
            </span>
            <span class="block text-[6px] md:text-xs">{{
              accountInfo.label
            }}</span>
          </div>
        </div>
        <div v-if="show">
          <logout />
        </div>
      </div>
    </div>
    <div
      v-if="showMenu"
      class="absolute h-full w-full z-[99]"
      style="background: rgba(0, 0, 0, 0.5)"
    >
      <div class="flex flex-col space-y-4 bg-white z-[999] h-full w-fit">
        <sideBar @closed="close" @close="close" />
      </div>
    </div>
  </div>
</template>

<script>
import Menu from "@/components/svg/menu.vue";
import Logo from "@/components/svg/logo.vue";
import status from "@/components/global/status.vue";
import { onMounted, computed, ref } from "vue";
import MobilLogo from "@/components/svg/mobilLogo.vue";
import { useStore } from "vuex";
import Logout from "@/views/logout.vue";
import sideBar from "./sideBar.vue";
export default {
  components: {
    Logo,
    MobilLogo,
    Menu,
    status,
    Logout,
    sideBar,
  },
  setup() {
    const store = useStore();
    const accountInfo = computed(() => store.getters["getMeInfo"]);
    const logPlace = ref(null);
    const show = ref(false);
    const showMenu = ref(false);
    function toggle() {
      show.value = !show.value;
    }
    function close() {
      console.log("hhhh");
      showMenu.value = false;
    }
    function toggleMenu() {
      showMenu.value = !showMenu.value;
    }
    onMounted(() => {
      console.log(accountInfo.value, "accountInfo");
      document.addEventListener("click", (event) => {
        if (logPlace.value && !logPlace.value.contains(event.target)) {
          show.value = false;
        }
      });
    });
    return {
      accountInfo,
      logPlace,
      toggle,
      show,
      close,
      toggleMenu,
      showMenu,
    };
  },
};
</script>

<style>
:deep(a.router-link-exact-active svg path),
:deep(a.router-link-active svg path) {
  fill: red;
}
:deep(a.router-link-active) {
  color: red;
  background-color: #fef5f6;
}
</style>
